import React from 'react';
import EnergyHero from "../components/EnergyComponent/EnergyHero";
import EnergyBoxInfo from "../components/EnergyComponent/EnergyBoxInfo";
import EnergyIntro from "../components/EnergyComponent/EnergyIntro";
import EnergyWhyUse from "../components/EnergyComponent/EnergyWhyUse";
import EnergyLatestNews from "../components/EnergyComponent/EnergyLatestNews";
import EnergyBlog from "../components/EnergyComponent/EnergyBlog";
import EnergyTeam from "../components/EnergyComponent/EnergyTeam";
import EnergyPartnersComment from "../components/EnergyComponent/EnergyPartnersComment";

const Energy = () => {
    return (
        <>
            <EnergyHero/>
            <EnergyBoxInfo/>
            <EnergyIntro/>
            <EnergyWhyUse/>
            <EnergyLatestNews/>
            <EnergyBlog/>
            <EnergyTeam/>
            <EnergyPartnersComment/>
        </>
    );
};

export default Energy;
