import React from 'react';
export  interface MiningLatestNewsDataType {
    id:number,
    type:boolean,
    latestNewsImage:string,
    latestNewsInfo:string,
    alt:string
}

const MiningLatestNews = (latestNews:any) => {
    return (
        <section className="py-5">
            <div className="container pt-4 pb-4">
                <div className="row">
                    <div className="col-md-4 align-self-center">
                        <div className="lc-block text-secondary">
                            <div > <p>Magna Sultriness </p></div>
                        </div>
                        <div className="lc-block border-bottom">
                            <div > <h1>Latest News</h1></div>
                        </div>
                    </div>

                    {latestNews.latestNews.map((item:MiningLatestNewsDataType) =>
                    item.type ?(
                        <div className="col-md-4" key={item.id}>
                            <div className="lc-block mb-2">
                                <img className="img-fluid"  src={item.latestNewsImage} style={{ height:'600px',backgroundSize:'cover',objectFit:'cover'}} alt={item.alt}/>
                            </div>
                            <div className="lc-block my-3">
                                <div >
                                    <p> {item.latestNewsInfo}</p>
                                </div>
                            </div>
                        </div>

                    ):<div className="col-md-4" key={item.id}>
                            <div className="lc-block my-3">
                                <div >
                                    <p>{item.latestNewsInfo}</p>
                                </div>
                            </div>
                            <div className="lc-block mb-2 ">
                                <img className="img-fluid"
                                     src={item.latestNewsImage} style={{ height:'600px',backgroundPosition:'center',objectFit:'cover'}} alt={item.alt}/>
                            </div>
                        </div>
                    )}


                </div>
            </div>

        </section>

    );
};

export default MiningLatestNews;
