import React from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import '../styles/contactus.css'

export interface FormValues  {
    name: string,
    email: string,
    description: string,
}

const ContactUs = () => {
    const contactUsTwo={
        backgroundImage:
            "url(../images/main-testimonial-bg.jpg)",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition:'center'
    };
    const { register,handleSubmit ,formState: { errors }} = useForm<FormValues>();
    return (
        <div className="d-flex " style={contactUsTwo}>
                  <div className="container">
                      <div className="row align-items-center">
                          <div className="col-md-6">
                              <div className="lc-block mb-4">

                                  <div className="form-body">
                                      <div className="row">
                                          <div className="form-holder">
                                              <div className="form-content">
                                                  <div className="form-items">
                                                      <h3>Contact Us</h3>
                                                      <p> Any visitor can fill out the form and submit it to send a message to the site owner. </p>



                                                      <form className="requires-validation" onSubmit={handleSubmit((data) => {
                                                          console.log(data)
                                                      })}>
                                                          <div className="col-md-12">
                                                              <input className="form-control" type="text" {...register("name" , { required: true })} placeholder="Full Name" />
                                                              {errors.name && <span>This field is required</span>}
                                                          </div>
                                                          <div className="col-md-12">
                                                              <input className="form-control" type="email" {...register("email", { required: true })} placeholder="E-mail Address" />
                                                              {errors.email && <span>This field is required</span>}
                                                          </div>
                                                          <div className="col-md-12">
                                                              <textarea className="form-control"   {...register("description", { required: true })}   placeholder="Your Message"   rows={3}> </textarea>
                                                              {errors.description && <span>This field is required</span>}
                                                          </div>
                                                          <div className="form-button mt-3 text-end">
                                                              <button id="submit" type="submit"   className="btn btn-primary">Send </button>
                                                          </div>
                                                      </form>




                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>


                          <div className="col-md-6 px-5">
                              <div className="lc-block mb-4">
                                  <div >
                                      <h2 className="fs-4">Find us!<p></p>
                                          <p></p>
                                      </h2>
                                  </div>
                              </div>
                              <div className="lc-block mb-4">
                                  <div >
                                      <p className="lead">972-413-0785 , 469-247-6545</p>
                                      <p className="lead">info@basaccountingsolutions.com</p>
                                      <p className="lead">info@arosuitesoft.com</p>

                                  </div>
                              </div>
                              <div className="lc-block mt-5 ">
                                  <div >
                                      <p className="findus">Any visitor can fill out the form and submit it to send a message to the site owner.hey slap an email address, phone,
                                          and location on a plain background and call it a day.</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

    );
};

export default ContactUs;
