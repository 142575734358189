import React from 'react';
import { useQuery } from "react-query";
import serviceImage from '../../images/img4.png'
import '../../styles/ipo.css'


export  interface ServiceItemProps {
    serviceItemData: {
        serviceImage:string;
        serviceTitle:string;
        serviceSubTitle:string;

        servicesSolutions: {
            id: number;
            serviceIcon:string;
            serviceItemHeading:string;
            serviceItemDescription:string;
        }[];
    };
}



const Services = (props: ServiceItemProps) => {
     const{ servicesSolutions }= props.serviceItemData

    return (
        <div>
            <section id="ipo" className="ipo-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ipo-image-area">
                                <img src={props.serviceItemData.serviceImage} alt=""/>
                            </div>
                        </div>
                        <div className="col-md-5 py-5">
                            <div className="ipo-info-area">
                                <div className="ipo-title-area">
                                    <h2> {props.serviceItemData.serviceTitle} </h2>
                                    <p className="lead">{props.serviceItemData.serviceSubTitle}</p>
                                </div>
                                <div className="ipo-point-area">
                                    {servicesSolutions.map(item=>(
                                        <div className="ipo-item mb-2">
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between ">
                                                    <div className="ipo-icon"><img src={item.serviceIcon} alt=""/> </div>
                                                    <div className="ipo-info">
                                                        <h5 className="card-title "> {item.serviceItemHeading}</h5>
                                                        <p className="card-text">
                                                            {item.serviceItemDescription}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Services;
