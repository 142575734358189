import React from 'react';
import MiningHero from '../components/MiningComponent/MiningHero';
import MiningBoxInfo from "../components/MiningComponent/MiningBoxInfo";
import MiningIntro from "../components/MiningComponent/MiningIntro";
import MiningWhyUse from "../components/MiningComponent/MiningWhyUse";
import MiningLatestNews from "../components/MiningComponent/MiningLatestNews";
import MiningPartnersComment from "../components/MiningComponent/MiningPartnersComment";
import MiningBlog from "../components/MiningComponent/MiningBlog";
import MiningTeam from "../components/MiningComponent/MiningTeam";
import '../styles/responsive.css'
import info from '../utils/miningdata'


const Mining = () => {

    return (
        <>
           <MiningHero  miningHeroData={info}/>
           <MiningBoxInfo miningBoxInfoData={info}/>
           <MiningIntro  miningIntroData={info}/>
            <MiningWhyUse miningWhyUseData={info}/>
            <MiningLatestNews latestNews={info.latestNews}/>
            <MiningBlog/>
            <MiningTeam miningTeamData={info}/>
            <MiningPartnersComment/>
        </>
    );
};

export default Mining;
