import React from 'react';
import '../../styles/feature.css'


export  interface AboutItemProps {
    aboutItemData: {
        aboutTitle:string;
        aboutSubTitle:string;

        aboutUs: {
            id: number;
            aboutIcon:string;
            aboutItemHeading:string;
            aboutItemDescription:string;
        }[];
    };
}



const AboutUs = (props: AboutItemProps) => {
   const{aboutUs}=props.aboutItemData;
    return (
        <section className="py-5">
            <div className="container">

                <div className="row justify-content-center">
                    <div className="lc-block text-center col-md-8">
                        <h2  className=" mb-0"><b>{props.aboutItemData.aboutTitle}</b></h2>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="lc-block text-center col-xxl-6 col-md-8">
                        <div> <p  className=""> {props.aboutItemData.aboutSubTitle}</p></div>
                    </div>
                </div>



                <div className="row pt-4">
                    {aboutUs.map(item=>(
                        <div className="col-md-4">
                            <div className="lc-block feature  p-4">
                                <div className="text-center">
                                    <img src={item.aboutIcon} alt="" style={{ width:"64px",color:"#212529"}}/>
                                    <h4 className="my-3" >{item.aboutItemHeading}</h4>
                                    <p >{item.aboutItemDescription}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                 </div>
            </div>
        </section>
    );
};

export default AboutUs;
