import React from 'react';
import '../../styles/mining.css'
const OilGasBlog = () => {
    return (

        <div className="d-flex text-center"
             style={{ minHeight:' 60vh',  backgroundSize:'cover', backgroundPosition: 'center center', backgroundImage: "url(../images/02.jpg)",}}>
            <div className="lc-block  my-3 align-self-center w-100 py-5">
                <div className="mb-5">
                    <h2 className="display-4 text-light fs-1 fw-bold">All For Good. Good For All.</h2>
                    <p className="lead text-light fw-normal">Lorem int ipsum dolor sit amet when an unknown printer took a galley of type.</p>
                </div>
                <a className="balsan_btn my-4" href="#" role="button">Go to Blog</a>
            </div>
        </div>
    );
};

export default OilGasBlog;

