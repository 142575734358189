import React from 'react';
import {BarChartOutline} from "react-ionicons";


export  interface MiningBoxInfoProps {
    miningBoxInfoData: {
        miningBoxInfo: {
            id:number;
            miningBoxHeading: string;
            miningBoxDescription: string
        }[];
    };
}

const MingBoxInfo = (props: MiningBoxInfoProps) => {
    const { miningBoxInfo } = props.miningBoxInfoData;

    return (
        <section>
            <div className="container-fluid">
                <div className="row mining-info-box">
                    {miningBoxInfo.map(item=>(
                        <a href="" className="col-lg-2 col-md-6 topservice-bg text-light text-center" style={{padding:"60px"}}>
                            <div className="lc-block mb-3">   <BarChartOutline color={'#ffffff'} height='50px' width='50px'/>,</div>
                            <div className="lc-block"> <div ><h3>{item.miningBoxHeading}</h3> </div> </div>
                            <div className="lc-block pb-1"> <hr className="w-25 bg-light"/> </div>
                            <div className="lc-block">  <div >  <p> {item.miningBoxDescription}</p> </div></div>
                        </a>
                    ))}
                </div>
            </div>

        </section>
    );
};

export default MingBoxInfo;
