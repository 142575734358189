import React from 'react';
import { LogoFacebook } from 'react-ionicons'
import { LogoLinkedin } from 'react-ionicons'
import { LogoYoutube } from 'react-ionicons'
import { LogoTwitter } from 'react-ionicons'
import BrandLogo from '../../images/logo.png'


const BrandArea = () => {
    return (
        <section className="py-3 brand-area">
            <div className="container-fluid ">

                <div className="row d-flex justify-content-between align-items-center">

                    <div className="col-md-4 ">
                        <div className="brand-logo"><a href="/"><img src={BrandLogo} alt=""/></a></div>
                    </div>

                    <div className="col-md-4">

                        <ul className="top-social float-sm-end ">
                            <li><a href="#">
                                <LogoFacebook
                                    color={'#ffffff'}
                                    height="25px"
                                    width="25px"
                                />
                            </a></li>
                            <li><a href="">
                                <LogoLinkedin
                                    color={'#ffffff'}
                                    height="25px"
                                    width="25px"
                                />

                            </a></li>
                            <li><a href="">
                                <LogoYoutube
                                    color={'#ffffff'}
                                    height="25px"
                                    width="25px"
                                />
                            </a></li>
                            <li><a href="">
                                <LogoTwitter
                                    color={'#ffffff'}
                                    height="25px"
                                    width="25px"
                                />

                            </a></li>
                        </ul>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default BrandArea;
