import React from 'react';
import { BarChartOutline } from 'react-ionicons'

export default {

    miningHeroImage:"../images/slide03.jpg",
    miningHeroHeading:"",
    miningHeroHeadingSpan:"Mining",
    miningHeroDescriptionOne:"Our team has been gelpingclients throughout the country for 10 years lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.",
    miningHeroBtnText:"Get it now",

    miningBoxInfo: [
        {
            id: 1,
            miningBoxIcon: <BarChartOutline color={'#ffffff'} height="50px" width="50px"/>,
            miningBoxHeading:"Thermal Power",
            miningBoxDescription:"Lorem ipsum dolor sit amet Lorem ipsum dolor",
        },
        {
            id: 2,
            miningBoxIcon: <BarChartOutline color={'#ffffff'} height="50px" width="50px"/>,
            miningBoxHeading:"Oil Platform",
            miningBoxDescription:"Lorem ipsum dolor sit amet Lorem ipsum dolor",
        },
        {
            id:3,
            miningBoxIcon: <BarChartOutline color={'#ffffff'} height="50px" width="50px"/>,
            miningBoxHeading:"Factory",
            miningBoxDescription:"Lorem ipsum dolor sit amet Lorem ipsum dolor",
        },
        {
            id: 4,
            miningBoxIcon: <BarChartOutline color={'#ffffff'} height="50px" width="50px"/>,
            miningBoxHeading:"Oil Platform",
            miningBoxDescription:"Lorem ipsum dolor sit amet Lorem ipsum dolor",
        },
        {
            id: 5,
            miningBoxIcon: <BarChartOutline color={'#ffffff'} height="50px" width="50px"/>,
            miningBoxHeading:"Gas Flaring",
            miningBoxDescription:"Lorem ipsum dolor sit amet Lorem ipsum dolor",
        },
        {
            id: 6,
            miningBoxIcon: <BarChartOutline color={'#ffffff'} height="50px" width="50px"/>,
            miningBoxHeading:"Oil Pump",
            miningBoxDescription:"Lorem ipsum dolor sit amet Lorem ipsum dolor",
        },
    ],

    introAreaHeading:'All For Good. Good For All.',
    introAreaDescription:'Aenean vel nisi in ipsum congue fermentum et ut arcu. Proin leo diam, vulputate  eu tellus ac, mattis cursus nunc. Aenean vel nisi in ipsum congue fermentum et ut arcu. Proin leo diam, vulputate eu tellus ac, mattis cursus nunc.',
    introInfo:[
        {
            id: 1,
            introInfoCount:'100%',
            introInfoTitle:'Satisfaction',
        },
        {
            id: 2,
            introInfoCount:'24/7',
            introInfoTitle:'Support',
        },
        {
            id: 3,
            introInfoCount:'+40k',
            introInfoTitle:'Products',
        },
    ],

    miningWhyArea: [
        {
            id: 1,
            miningWhyImage:"../images/mg1.jpg",
            miningWhyHeading:"Chemical Industry",
            miningWhyDescription:"Cillum doloreu fugiat nulla pariatur excepteur si occaecat cupdatat non proident sunt culpaq officia deserunt mollt animest laborum sed perspiciatis unde omnis iste natus errosit voluptatem accuantium doloremque laudantium totam.",
            imageRight: "order-lg-2",
            textLeft: "order-lg-1"

        },
        {
            id: 2,
            miningWhyImage:"../images/02.jpg",
            miningWhyHeading:"Saving Technologies",
            miningWhyDescription:"Cillum doloreu fugiat nulla pariatur excepteur si occaecat cupdatat non proident sunt culpaq officia deserunt mollt animest laborum sed perspiciatis unde omnis iste natus errosit voluptatem accuantium doloremque laudantium totam.",
            imageRight: "",
            textLeft: ""
        },
        {
            id: 3,
            miningWhyImage:"../images/03.jpg",
            miningWhyHeading:"Military Industry",
            miningWhyDescription:"Cillum doloreu fugiat nulla pariatur excepteur si occaecat cupdatat non proident sunt culpaq officia deserunt mollt animest laborum sed perspiciatis unde omnis iste natus errosit voluptatem accuantium doloremque laudantium totam.",
            imageRight: "order-lg-2",
            textLeft: "order-lg-1"
        },
    ],

    miningLatestNewsIntro:'magna ultricies',
    miningLatestNewsHeading:'Latest News',


    miningAreaTeamHeading:'Our Team',
    miningAreaTeamSubHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et metus',
    ourTeam: [
        {
            id: 1,
            miningImage:"../images/team1.jpg",
            miningTeamName:"Neil Amstrong",
            miningTeamTitle:"SOME LIPSUM",
            miningTeamDis:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus leo nulla, suscipit non dapibus vitae, dignissim id quam. Donec eget nulla tellus. "
        },
        {
            id: 2,
            miningImage:"../images/team2.jpg",
            miningTeamName:"Neil Amstrong",
            miningTeamTitle:"SOME LIPSUM",
            miningTeamDis:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus leo nulla, suscipit non dapibus vitae, dignissim id quam. Donec eget nulla tellus. ",
        },
        {
            id: 3,
            miningImage:"../images/team3.jpg",
            miningTeamName:"Neil Amstrong",
            miningTeamTitle:"SOME LIPSUM",
            miningTeamDis:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus leo nulla, suscipit non dapibus vitae, dignissim id quam. Donec eget nulla tellus. ",
        },
        {
            id:4,
            miningImage:"../images/team4.jpg",
            miningTeamName:"Neil Amstrong",
            miningTeamTitle:"SOME LIPSUM",
            miningTeamDis:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus leo nulla, suscipit non dapibus vitae, dignissim id quam. Donec eget nulla tellus. ",
        },

    ],


    latestNewsTitle:"Latest News",
    latestNewsSubtitle:"magna ultricies",
    latestNews:[
        {
            id:1,
            type:true,
            latestNewsImage:"../images/02.jpg",
            latestNewsInfo:'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. ',
            alt:"Pellentesque"
        },
        {
            id:2,
            type:false,
            latestNewsImage:"../images/slide01.jpg",
            latestNewsInfo:'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. ',
            alt:"Pellentesque"
        },
    ]
}
