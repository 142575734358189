import React from 'react';

const EnergyLatestNews = () => {
    return (
        <section className="py-5">
            <div className="container pt-4 pb-4">
                <div className="row">
                    <div className="col-md-4 align-self-center">
                        <div className="lc-block text-secondary">
                            <div > <p>magna ultricies </p></div>
                        </div>
                        <div className="lc-block border-bottom">
                            <div > <h1>Latest News</h1></div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="lc-block mb-2">
                            <img className="img-fluid"
                                 src={"../images/02.jpg"} style={{ height:'600px',backgroundSize:'cover',objectFit:'cover'}}/>
                        </div>
                        <div className="lc-block">
                            <div >
                                <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis
                                    egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                                    Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris
                                    placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra.&nbsp;</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="lc-block">
                            <div >
                                <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis
                                    egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                                    Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris
                                    placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra.</p>
                            </div>
                        </div>
                        <div className="lc-block mb-2">
                            <img className="img-fluid"
                                 src={"../images/slide01.jpg"} style={{ height:'600px',backgroundPosition:'center',objectFit:'cover'}}/>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    );
};

export default EnergyLatestNews;
