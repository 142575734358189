import React from 'react';
import info from "../../utils/miningdata";

const secondarea = {
    backgroundImage:
        "url(../images/main-testimonial-bg.jpg)",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition:'center center',
};

const EnergyIntro = () => {
    return (
        <section className="d-flex  second-area my-auto" style={secondarea}>
            <div className="container" style={{paddingTop: '10vh',paddingBottom:'10vh'}}>
                <div className="row align-items-center px-2">
                    <div className="col-12 col-md-7 col-lg-6">
                        <div className="lc-block mb-4">
                            <div >
                                <h1>{info.introAreaHeading}</h1>
                                <p>{info.introAreaDescription}</p>
                            </div>
                        </div>

                        <div className="lc-block d-flex mb-5">
                            {info.introInfo.map(item=>(
                                <>
                                    <div className="px-3">
                                        <h3 className="h2 mb-0 text-secondary" >{item.introInfoCount}</h3>
                                        <span  className="mb-0 text-muted"> {item.introInfoTitle} </span>
                                    </div>
                                    <div className="border-start"></div>
                                </>
                            ))}
                        </div>
                    </div>
                    <div className="col-12 col-md-5 col-lg-6">
                        <div className="lc-block"><img alt="" className="img-fluid mx-auto d-block"
                                                       src={"../images/miningintro.jpg"}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EnergyIntro;
