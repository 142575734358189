import React from 'react';
import Slider from "../components/HomeComponent/Slider";
import Services from "../components/HomeComponent/Services";
import Arosuit from "../components/HomeComponent/Arosuit";
import AboutUs from "../components/HomeComponent/AboutUs";
import WhyUse from "../components/HomeComponent/WhyUse";
import AccountingServices from "../components/HomeComponent/AccountingServices";
import ResourcesInsightsNewsFeed from "../components/HomeComponent/ResourcesInsightsNewsFeed";
import '../styles/responsive.css'
import info from '../utils/data'

const Home = () => {
    return (
        <>
          <Slider  sliderItemData={info}/>
          <Services serviceItemData={info}/>
          <Arosuit  arosuitItemData={info}/>
          <AboutUs  aboutItemData={info}/>
          <WhyUse whyItemData={info}/>
          <AccountingServices acsItemData={info}/>
          <ResourcesInsightsNewsFeed  newsItemData={info}/>
        </>
    );
};

export default Home;
