import React from 'react';
import { Link } from 'react-router-dom';
import BrandLogo from '../../images/logo1.png'
const MenuArea = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-one sticky-top">
            <div className="container-fluid">
                <Link className="navbar-brand" to={`/`}><img src={BrandLogo} alt=""/></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="main_nav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item active"><Link className="nav-link custom-link" to="/">Home </Link></li>
                        <li className="nav-item"><Link className="nav-link custom-link" to="/about">About Us</Link></li>

                        <li className="nav-item dropdown" id="one">
                            <a className="nav-link dropdown-toggle custom-link" href="#"
                               data-bs-toggle="dropdown">Services & Solutions</a>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item custom-sub-link" to="https://www.arosuitesoft.com/" target='_blank' >ARO SuiteSoft </Link></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown" id="three">
                            <a className="nav-link dropdown-toggle custom-link" href="#"
                               data-bs-toggle="dropdown">Industries</a>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item custom-sub-link" to={`/mining`}>Mining</Link></li>
                                 <li><Link className="dropdown-item custom-sub-link" to={`/oilandgas`}>Oil and Gas</Link></li>
                                <li><Link className="dropdown-item custom-sub-link" to={`/energy`}>Energy</Link></li>
                                <li><Link className="dropdown-item custom-sub-link" to="#">Agriculture</Link></li>
                                <li><Link className="dropdown-item custom-sub-link" to="#">Other</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item"><Link className="nav-link custom-link" to={`/contactus`}>Contact Us</Link></li>
                        <li className="nav-item "><Link className="nav-link custom-link" to="#">Portal Login</Link></li>

                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default MenuArea;
