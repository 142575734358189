import React from 'react';
import {BarChartOutline} from "react-ionicons";
import info from '../../utils/miningdata'
const MingBoxInfo = () => {
    return (
        <section>
            <div className="container-fluid">
                <div className="row">
                    {info.miningBoxInfo.map(item=>(
                        <div className="col-lg-2 col-md-6 topservice-bg text-light text-center" style={{padding:"60px"}}>
                            <div className="lc-block mb-3">  {item.miningBoxIcon} </div>
                            <div className="lc-block"> <div ><h3>{item.miningBoxHeading}</h3> </div> </div>
                            <div className="lc-block pb-1"> <hr className="w-25 bg-light"/> </div>
                            <div className="lc-block">  <div >  <p> {item.miningBoxDescription}</p> </div></div>
                        </div>
                    ))}
                </div>
            </div>

        </section>
    );
};

export default MingBoxInfo;
