import React from 'react';
import '../../styles/mininghero.css'



export  interface MiningHeroProps {
    miningHeroData: {
            miningHeroImage: string;
            miningHeroHeading: string;
            miningHeroHeadingSpan: string;
            miningHeroBtnText: string;
            miningHeroDescriptionOne: string;

    };
}


const MiningHero = (props: MiningHeroProps) => {

    return (

        <section className="overflow-hidden mining-hero-area">
            <div className="container-fluid col-xxl-8">
                <div className="row flex-lg-nowrap align-items-center g-5">
                    <div className="order-lg-1 w-100">
                        <img   style={{clipPath:"polygon(18% 0, 100% 0%, 100% 100%, 0% 100%)"}}
                             src={props.miningHeroData.miningHeroImage}
                             className="d-block mx-lg-auto img-fluid" alt="Photo by Milad Fakurian" loading="lazy"
                             srcSet={props.miningHeroData.miningHeroImage}
                             sizes="(max-width: 1080px) 100vw, 1080px" width="2160" height="100vh"/>
                    </div>
                    <div className="col-lg-6 col-xl-5 text-center text-lg-start pt-lg-5 mt-xl-4">
                        <div className="lc-block mb-4"> <div > <h1 className="fw-bold display-3 hero-heading">{props.miningHeroData.miningHeroHeading} <span>{props.miningHeroData.miningHeroHeadingSpan}</span></h1> </div>  </div>
                        <div className="lc-block mb-5">   <div>  <p className="rfs-8"> {props.miningHeroData.miningHeroDescriptionOne}</p> </div> </div>
                        <div className="lc-block mb-6"><a className="btn btn-primary px-4 me-md-2 btn-lg" href="#" role="button">{props.miningHeroData.miningHeroBtnText}</a>  </div>
                    </div>

                </div>
            </div>
        </section>

);
};

export default MiningHero;
