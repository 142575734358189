import React from 'react';
import { useQuery } from "react-query";
import '../../styles/AccountingServices.css'
import info from '../../../src/utils/data'



export  interface ASCItemProps {
    acsItemData: {
        accountingTitle:string;
        accountingSubTitle:string;

        accountingServices: {
            id: number;
            accountServiceIcon:string;
            accountServiceItemHeading:string;
            accountServiceItemDescription:string;
            accountServiceBg:string;
            accountServiceText:string;
            iconColor:string;
        }[];
    };
}

;

const AccountingServices = (props: ASCItemProps) => {
  const{accountingServices}=props.acsItemData;
    return (
        <section>
            <div className="container py-5">

                <div className="row justify-content-center">
                    <div className="lc-block text-center col-md-8">
                        <div>  <h2  className=" mb-0">{props.acsItemData.accountingTitle}</h2></div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="lc-block text-center col-xxl-6 col-md-8">
                        <div> <p  className=""> {props.acsItemData.accountingSubTitle}</p></div>
                    </div>
                </div>

                <div className="row">
                    {accountingServices.map(item=>(
                        <div className="col-lg-4 mb-4">
                            <div className={`lc-block text-center rounded shadow ${item.accountServiceText}  ${item.accountServiceBg} px-4 py-5`} style={{minHeight:"50vh"}}>
                                <img src={item.accountServiceIcon} alt="" style={{width:"62px"}} className={`${item.iconColor}`}/>
                                <h4 className="mt-3 mb-4" >{item.accountServiceItemHeading}</h4>
                                <p className=""> {item.accountServiceItemDescription}</p>
                            </div>
                        </div>
                    ))}
                </div>


        </div>
</section>
    );
};

export default AccountingServices;
