import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import  Navbar from '../src/components/NavbarComponent/Navbar'
import Home from '../src/containers/Home'
import '../src/styles/style.css'
import ContactUs from "./containers/ContactUs";
import Mining from "./containers/Mining";
import '../src/styles/responsive.css'
import OilandGas from "./containers/OilandGas";
import Energy from "./containers/Energy";
import Footer from "./components/Footer";
import About from "./containers/About";

function App() {
  return (
    <>
        <Router>
            <Navbar/>
            <div className="main-contend-area">
                <Routes>
                    <Route  path="/" element={<Home />} />
                    <Route  path="/contactus" element={<ContactUs />}/>
                    <Route  path="/mining" element={<Mining />}/>
                    <Route  path="/oilandgas" element={<OilandGas/>}/>
                    <Route  path="/energy" element={<Energy/>}/>
                    <Route  path="/about" element={<About/>}/>
                </Routes>
            </div>
            <Footer/>
        </Router>
    </>
  );
}

export default App;
