import React from 'react';
import arosuiteBackground from '../../images/img3.webp'
import '../../styles/fractional.css'
// import {ServiceItemType} from "./Services";
import {useQuery} from "react-query";
import info from '../../../src/utils/data'


export  interface ServiceItemProps {
    arosuitItemData: {
        arosuitTitle:string;
        arosuitSubTitle:string;

        aroSuit: {
            id: number;
            aroSuitItemHeading:string;
            aroSuitItemDescription:string;
        }[];
    };
}





const Arosuit = (props:ServiceItemProps) => {
  const{aroSuit} = props.arosuitItemData;
    return (
        <div>
            <section id="fractional" className=" fractional-area">
                <div className="container-fluid ">
                    <div className="row">
                        <div   className="col-md-6  px-5 py-5">
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="fractional-title-area">
                                        <h2>Introducing AROSUITE</h2>
                                        <p className="text-muted lead">
                                            Invest in thousands of stocks with as little as $1000.
                                        </p>
                                    </div>
                                </div>

                                {aroSuit.map(item=>(
                                    <div className="col-md-6">
                                        <div className="fractional-item my-2">
                                            <div className="card bg-cream">
                                                <div className="card-body">
                                                    <h5 className="card-title">{item.aroSuitItemHeading}</h5>
                                                    <p className="card-text">
                                                        {item.aroSuitItemDescription}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                        <div className="col-md-6" style={{ minHeight:'50vh' , backgroundSize:'cover' , backgroundPosition:'center center' , backgroundImage: `url(${arosuiteBackground})`}}  >
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Arosuit;
