import React from 'react';
import { BarChartOutline } from 'react-ionicons'
import serviceImage from "../images/img4.png";

export default {
    slider: [
        {
            id: 1,
            sliderImage: "../images/slide2.jpg",
            sliderHeading:"Why BALSAM Accounting Solutions?",
            sliderDescription:"Join Us. Explore fulfilling career opportunities at BA."
        },
        {
            id: 2,
            sliderImage: "../images/slide3.jpg",
            sliderHeading:"BALSAM Accounting Services Announce Merger",
            sliderDescription:"Join Us. Explore fulfilling career opportunities at BA."
        },
        {
            id: 3,
            sliderImage: "../images/slide4.jpg",
            sliderHeading:"IRS Announces New Fee for Estate Tax Closing Letters",
            sliderDescription:"Join Us. Explore fulfilling career opportunities at BA."
        }
    ],

    serviceImage:"../images/img4.png",
    serviceTitle:"services & solutions",
    serviceSubTitle:"Get in at the IPO price. Now, you can become one of the first public investors in upcoming IPOs.",

    servicesSolutions: [
        {
            id:1,
            serviceIcon:'../images/img12.svg',
            serviceItemHeading:'Client Accounting Services (CAS)',
            serviceItemDescription:'Request shares in new companies before their stock starts trading on public exchanges'
        },
        {
            id: 2,
            serviceIcon:"../images/img7.svg",
            serviceItemHeading:"Tax Planning & Tax Return Preparation",
            serviceItemDescription:"Request shares in new companies before their stock starts trading on public exchanges."
        },
        {
            id: 3,
            serviceIcon:"../images/im8.svg",
            serviceItemHeading:"Comprehensive Financial Planning",
            serviceItemDescription:"Request shares in new companies before their stock starts trading on public exchanges."
        }
    ],

    arosuitTitle:"Introducing AROSUITE",
    arosuitSubTitle:"Invest in thousands of stocks with as little as $1000.",

    aroSuit: [
        {
            id: 1,
            aroSuitItemHeading:"Invest Any Amount",
            aroSuitItemDescription:" Choose how much you want to invest, and we’ll convert from dollars to parts of a whole share.",
            aroSuitImage:'',
            imgOrder: "",
            textOrder: ""
        },
        {
            id: 2,
            aroSuitItemHeading:"Build a Balanced Portfolio",
            aroSuitItemDescription:"  Customize your portfolio with pieces of different companies and funds to help reduce risk. Customize your portfolio with pieces of different companies and funds to help reduce risk.",
            aroSuitImage:'',
            imgOrder: "",
            textOrder: ""
        },
        {
            id: 3,
            aroSuitItemHeading:"Trade in Real Time",
            aroSuitItemDescription:"  Trades placed during market hours are executed at that time, so you’ll always know the share price.",
            aroSuitImage:'',
            imgOrder: "",
            textOrder: ""
        },
        {
            id: 4,
            aroSuitItemHeading:"Business Advisory",
            aroSuitItemDescription:"  Trades placed during market hours are executed at that time,so you’ll always know the share price.",
            aroSuitImage:'',
            imgOrder: "",
            textOrder: ""
        }
    ],

    aboutTitle:"About Us",
    aboutSubTitle:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et metus  id ligula malesuada placerat sit amet quis enim.",
    aboutUs: [
        {
            id: 1,
            aboutIcon:"../images/icon1.svg",
            aboutItemHeading:"Tax planning and preparation",
            aboutItemDescription:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse a lacus est.&nbsp;"
        },
        {
            id: 2,
            aboutIcon:"../images/icon2.svg",
            aboutItemHeading:"Accounting",
            aboutItemDescription:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse a lacus est.&nbsp;"
        },
        {
            id: 3,
            aboutIcon:"../images/icon3.svg",
            aboutItemHeading:"Bookkeeping",
            aboutItemDescription:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse a lacus est.&nbsp;"
        },
        {
            id: 4,
            aboutIcon:"../images/icon4.svg",
            aboutItemHeading:"Tax resolution services",
            aboutItemDescription:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse a lacus est.&nbsp;"
        },
        {
            id: 5,
            aboutIcon:"../images/icon5.svg",
            aboutItemHeading:"Consulting services",
            aboutItemDescription:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse a lacus est.&nbsp;"
        },
        {
            "id": 6,
            "aboutIcon":"../images/icon6.svg",
            "aboutItemHeading":"Payroll Services",
            "aboutItemDescription":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse a lacus est.&nbsp;"
        },
        {
            id: 7,
            aboutIcon:"../images/icon7.svg",
            aboutItemHeading:"Risk Advisory",
            aboutItemDescription:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse a lacus est.&nbsp;"
        },
        {
            id: 8,
            aboutIcon:"../images/icon8.svg",
            aboutItemHeading:"Legal",
            aboutItemDescription:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse a lacus est.&nbsp;"
        },
        {
            id: 9,
            aboutIcon:"../images/icon9.svg",
            aboutItemHeading:"Tax",
            aboutItemDescription:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse a lacus est.&nbsp;",
        }
    ],
    whyArea: [
        {
            id: 1,
            whyImage:"https://images.unsplash.com/photo-1558985590-e84f133009b2?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1352&amp;q=80",
            whyUseItemHeading:"Why BALSAM Accounting Solutions?",
            whyUseItemDescription:"Lorem ipsum dolor sit amet Neque porro quisquam est qui dolorem Lorem int ipsum dolor sit amet when an unknown printer took a galley of type. Vivamus id tempor felis. Cras sagittis mi sit amet malesuada mollis. Mauris porroinit consectetur cursus tortor vel interdum.",
            imgOrder: "order-lg-2",
            textOrder: "order-lg-1"

        },
        {
            id: 2,
            whyImage:"https://images.unsplash.com/photo-1491926626787-62db157af940?ixlib=rb-1.2.1&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;h=768&amp;fit=crop&amp;ixid=eyJhcHBfaWQiOjM3ODR9",
            whyUseItemHeading:"Why Use AROSUITE ?",
            whyUseItemDescription:"Lorem ipsum dolor sit amet Neque porro quisquam est qui dolorem Lorem int ipsum dolor sit amet when an unknown printer took a galley of type. Vivamus id tempor felis. Cras sagittis mi sit amet malesuada mollis. Mauris porroinit consectetur cursus tortor vel interdum.",
            imgOrder: "",
            textOrder: ""
        }
    ],

    accountingTitle:"Accounting Services",
    accountingSubTitle:" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et metus id \n"+ " ligula malesuada placerat sit amet quis enim. ",


    accountingServices: [
        {
            id: 1,
            accountServiceIcon:"../images/icon10.svg",
            accountServiceItemHeading:"Tax planning and preparation ",
            accountServiceItemDescription:"In feugiat nulla euismod felis ultrices, eu rhoncus massa condimentum. Vivamus nulla eros, vestibulum in egestas ut, dapibus vel risus. Duis lacus diam, blandit rhoncus feugiat in, rhoncus eget orci. Praesent semper, ex et condimentum viverra, nibh magna congue metus, et elementum dui nibh nec est. ",
            accountServiceBg:"bg-light ",
            accountServiceText: "text-dark",
            iconColor: "black"

        },
        {
            id:2,
            accountServiceIcon:"../images/icon11.svg",
            accountServiceItemHeading:"Tax resolution services",
            accountServiceItemDescription:"In feugiat nulla euismod felis ultrices, eu rhoncus massa condimentum. Vivamus nulla eros, vestibulum in egestas ut, dapibus vel risus. Duis lacus diam, blandit rhoncus feugiat in, rhoncus eget orci. Praesent semper, ex et condimentum viverra, nibh magna congue metus, et elementum dui nibh nec est. ",
            accountServiceBg:"bg-two",
            accountServiceText: "text-light",
            iconColor: "white"
        },
        {
            id:3,
            accountServiceIcon:"../images/icon12.svg",
            accountServiceItemHeading:"Consulting services",
            accountServiceItemDescription:"In feugiat nulla euismod felis ultrices, eu rhoncus massa condimentum. Vivamus nulla eros, vestibulum in egestas ut, dapibus vel risus. Duis lacus diam, blandit rhoncus feugiat in, rhoncus eget orci. Praesent semper, ex et condimentum viverra, nibh magna congue metus, et elementum dui nibh nec est. ",
            accountServiceBg:"bg-light ",
            accountServiceText: "text-dark",
            iconColor: "black"
        }
    ],

    newsTitle:"Resources, Insights, & News Feed",
    newsSubTitle:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et metus id ligula malesuada placerat sit amet quis enim.",
    ResourcesInsightsNewsFeed: [
        {
            id: 1,
            newsFeedIcon:"../images/icon13.svg",
            newsFeedItemHeading:"BALSAM Accounting Services Announce Merger",
            newsFeedItemDescriptionOne:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et metus id ligula malesuada placerat sit amet quis enim.",
            newsFeedItemDescriptionTwo:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et metus id ligula malesuada placerat sit amet quis enim.",
            newsFeedBg:"bg-two ",
            newsFeedText: "text-light"
        },
        {
            id: 2,
            newsFeedIcon:"../images/icon14.svg",
            newsFeedItemHeading:"IRS Announces New Fee for Estate Tax Closing Letters",
            newsFeedItemDescriptionOne:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et metus id ligula malesuada placerat sit amet quis enim.",
            newsFeedItemDescriptionTwo:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et metus id ligula malesuada placerat sit amet quis enim.",
            newsFeedBg:"bg-light",
            newsFeedText: "text-secondary"
        },
        {
            id: 3,
            newsFeedIcon:"../images/icon13.svg",
            newsFeedItemHeading:"Cloud Accounting & Payroll Integration: A Match Made in Heaven",
            newsFeedItemDescriptionOne:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et metus id ligula malesuada placerat sit amet quis enim.",
            newsFeedItemDescriptionTwo:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et metus id ligula malesuada placerat sit amet quis enim.",
            newsFeedBg:"bg-two ",
            newsFeedText: "text-light"
        },
        {
            id: 4,
            newsFeedIcon:"../images/icon14.svg",
            newsFeedItemHeading:"Inflation-adjusted civil penalties",
            newsFeedItemDescriptionOne:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et metus id ligula malesuada placerat sit amet quis enim.Nunc et metus .",
            newsFeedItemDescriptionTwo:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et metus id ligula malesuada placerat sit amet quis enim.Nunc et metus id ligula malesuada placerat sit.",
            newsFeedBg:"bg-light",
            newsFeedText: "text-secondary"
        }
    ],




      introAreaHeading:'All For Good. Good For All.',
      introAreaDescription:'Aenean vel nisi in ipsum congue fermentum et ut arcu. Proin leo diam, vulputate  eu tellus ac, mattis cursus nunc. Aenean vel nisi in ipsum congue fermentum et ut arcu. Proin leo diam, vulputate eu tellus ac, mattis cursus nunc.',
      introInfo:[
          {
              id: 1,
              introInfoCount:'100%',
              introInfoTitle:'Satisfaction',
          },
          {
              id: 2,
              introInfoCount:'24/7',
              introInfoTitle:'Support',
          },
          {
              id: 3,
              introInfoCount:'+40k',
              introInfoTitle:'Products',
          },
      ]
}