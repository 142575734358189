import React from 'react';
import OilGasHero from "../components/OilandGasComponent/OilGasHero";
import OilGasBoxInfo from "../components/OilandGasComponent/OilGasBoxInfo";
import OilGasIntro from "../components/OilandGasComponent/OilGasIntro";
import OilGasWhyUse from "../components/OilandGasComponent/OilGasWhyUse";
import OilGasLatestNews from "../components/OilandGasComponent/OilGasLatestNews";
import OilGasBlog from "../components/OilandGasComponent/OilGasBlog";
import OilGasTeam from "../components/OilandGasComponent/OilGasTeam";
import OilGasPartnersComment from "../components/OilandGasComponent/OilGasPartnersComment";

const OilandGas = () => {
    return (
        <>
            <OilGasHero/>
            <OilGasBoxInfo/>
            <OilGasIntro/>
            <OilGasWhyUse/>
            <OilGasLatestNews/>
            <OilGasBlog/>
            <OilGasTeam/>
            <OilGasPartnersComment/>
        </>
    );
};

export default OilandGas;
