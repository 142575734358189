import React from 'react';
import BrandArea from "./BrandArea";
import MenuArea from "./MenuArea";
import  '../../styles/navbar.css'

const Navbar = () => {
    return (
        <>
          <BrandArea/>
          <MenuArea/>
        </>
    );
};

export default Navbar;
