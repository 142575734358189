import React from 'react';

import info from '../../utils/miningdata'



const EnergyWhyUse = () => {
    return (
        <section>
            <div className="container-fluid p-0 overflow-hidden ">
                {info.miningWhyArea.map(item=>(
                    <div className="row g-0 bg-secondary">
                        <div  className={`col-lg-6 ${item.imageRight}`}
                              style={{
                                  backgroundImage:`url(${item.miningWhyImage})`,
                                  minHeight: '60vh',
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition:'center center'
                              }}>
                        </div>
                        <div className={`col-lg-6 ${item.textLeft} my-auto  px-5 py-5  text-light`}>
                            <div className="lc-block">
                                <div >
                                    <h1>{item.miningWhyHeading}</h1>
                                    <p className="lead">{item.miningWhyDescription}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default EnergyWhyUse;
