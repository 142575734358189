import React from 'react';
import '../styles/about.css'
const About = () => {
    return (
        <>
            <section>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="lc-block">
                                <div >

                                    <p className="lead fw-bold text-secondary">About us</p>

                                    <h1>BALSAM</h1>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="lc-block">
                                <div>
                                    <p className=" lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                                        et metus id ligula malesuada placerat sit amet quis enim. Aliquam erat volutpat.
                                        In pellentesque scelerisque auctor. Ut porta lacus eget nisi fermentum lobortis.
                                        Vestibulum facilisis tempor ipsum, ut rhoncus magna ultricies laoreet. Proin
                                        vehicula erat eget libero accumsan iaculis.<br/></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="d-flex container-fluid"
                    style={{ minHeight:' 60vh',  backgroundSize:'cover', backgroundPosition: 'center center', backgroundImage: "url(../images/03.jpg)",}}>
                </div>
                <div className="container p-5 bg-light shadow" style={{ marginTop:'-100px' }}>
                    <div className="row text-center justify-content-center">
                        <div className="col-12">
                            <div className="lc-block">
                                <div >
                                    <p className="fw-bold display-5">Our Company</p>
                                </div>
                            </div>
                        </div>
                        <div className="lc-block col-5">
                            <hr/>
                        </div>
                    </div>
                    <div className="row text-center justify-content-center">
                        <div className="col-lg-10 col-xxl-8">
                            <div className="lc-block mb-5">
                                <div >
                                    <p className="lead"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                                        et metus id ligula malesuada placerat sit amet quis enim. Aliquam erat volutpat.
                                        In pellentesque scelerisque auctor.
                                        Ut porta lacus eget nisi fermentum lobortis. Vestibulum facilisis tempor
                                        ipsum, ut rhoncus magna ultricies laoreet. Proin vehicula erat eget libero
                                        accumsan iaculis. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container py-5">
                    <div className="row mb-2">
                        <div className="col-md-12 text-center">
                        </div>
                    </div>


                    <div className="row counters">
                        <div className="col-lg-4 text-center">
                            <div className="lc-block mb-3">

                                <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" fill="currentColor"
                                     viewBox="0 0 16 16" lc-helper="svg-icon">
                                    <path
                                        d="M9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276Z"></path>
                                </svg>

                            </div>

                            <div className="lc-block">
                                <span className="display-4" ><b className="fw-bold">274</b></span>
                                <div >Customers</div>
                            </div>
                        </div>

                        <div className="col-lg-4 text-center">
                            <div className="lc-block mb-3">

                                <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" fill="currentColor"
                                     viewBox="0 0 16 16"  lc-helper="svg-icon">
                                    <path d="M8 9.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
                                    <path
                                        d="M9.5 2c-.9 0-1.75.216-2.501.6A5 5 0 0 1 13 7.5a6.5 6.5 0 1 1-13 0 .5.5 0 0 1 1 0 5.5 5.5 0 0 0 8.001 4.9A5 5 0 0 1 3 7.5a6.5 6.5 0 0 1 13 0 .5.5 0 0 1-1 0A5.5 5.5 0 0 0 9.5 2zM8 3.5a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"></path>
                                </svg>

                            </div>
                            <div className="lc-block">
                                <span className="display-4" ><b className="fw-bold">1.244</b></span>
                                <div >
                                    <p>Hours</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 text-center">
                            <div className="lc-block mb-3">

                                <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" fill="currentColor"
                                     viewBox="0 0 16 16" lc-helper="svg-icon">
                                    <path
                                        d="M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-8zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-11z"></path>
                                </svg>

                            </div>
                            <div className="lc-block">
                                <span className="display-4" ><b className="fw-bold">+AAA</b></span>
                                <div >Value</div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row align-items-md-center mb-5">
                        <div className="col-md-12">
                        </div>
                    </div>
                    <div className="row align-items-md-center mb-5">
                        <div className="col-md-6 pr-5">
                            <div className="lc-block py-2"><span className="badge bg-secondary" >COMPANY INFO</span>
                            </div>
                            <div className="lc-block mb-4">
                                <h2  className="fw-bold display-5">Working Together, to build great
                                    projects.</h2>
                            </div>
                            <div className="lc-block">


                                <div className="row">
                                    <div className="col-6">
                                        <div className="lc-block mb-4">
                                            <p  className=" m-0" style={{fontSize:'40px'}}>3+</p>
                                            <p  className="small text-uppercase">Branches</p>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="lc-block mb-4">
                                            <p  className=" m-0" style={{fontSize:'40px'}}>48+</p>
                                            <p className="small text-uppercase">Offices</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="lc-block mb-4">
                                            <p  className=" m-0" style={{fontSize:'40px'}}>33+</p>
                                            <p  className="small text-uppercase">Events over the
                                                world</p>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="lc-block mb-4">
                                            <p  className=" m-0" style={{fontSize:'40px'}}>4800+</p>
                                            <p  className="small text-uppercase">Members</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lc-block text-muted">
                                <div >
                                    <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et metus id ligula
                                        malesuada placerat sit amet quis enim. Aliquam erat volutpat.&nbsp;</p>
                                    <p>In pellentesque scelerisque auctor. Ut porta lacus eget nisi fermentum lobortis.
                                        Vestibulum facilisis tempor
                                        ipsum, ut rhoncus magna ultricies laoreet. Proin vehicula erat eget libero
                                        accumsan iaculis.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="lc-block">
                                <div className="card shadow-sm border-light">
                                    <img
                                        src="../images/01.jpg"
                                        alt="Photo by Leohoho" className="card-img-top" loading="lazy"
                                        srcSet="../images/01.jpg"
                                        sizes="(max-width: 1080px) , 1080px"/>
                                        <div className="card-body">
                                            <h3 className="card-title fw-bold" >Communicate</h3>
                                            <p className="card-text" >Some quick example text to build
                                                on the card title</p>
                                        </div>
                                </div>
                            </div>
                            <div className="lc-block">
                                <div className="card shadow-sm border-light mt-4">
                                    <img
                                        src="../images/02.jpg"
                                        alt="Photo by Leohoho" className="card-img-top" loading="lazy"
                                        srcSet="../images/02.jpg"
                                        sizes="(max-width: 1080px) , 1080px"/>
                                        <div className="card-body">
                                            <h3 className="card-title fw-bold" >Devices</h3>
                                            <p className="card-text" >Some quick example text to build
                                                on the card title</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 pl-sm-3 mt-3 mt-sm-0">
                            <div className="lc-block">
                                <div className="card shadow-sm border-light">
                                    <img
                                        src="../images/03.jpg"
                                        alt="Photo by Leohoho" className="card-img-top" loading="lazy"
                                        srcSet="../images/03.jpg"
                                        sizes="(max-width: 1080px) , 1080px"/>
                                        <div className="card-body">
                                            <h3 className="card-title fw-bold" >Productivity</h3>
                                            <p className="card-text" >Some quick example text to build
                                                on the card title</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section className="py-6">
                <div className="container my-5">
                    <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
                        <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
                            <div className="lc-block mb-3">
                                <div >
                                    <h2 className="fw-bold display-6">What Our Clients Say:<p></p>
                                        <p></p>
                                    </h2>
                                </div>
                            </div>

                            <div className="lc-block mb-3">
                                <div className="d-inline-flex">
                                    <div>
                                        <svg xmlns="" width="2em" height="2em"
                                             fill="currentColor" viewBox="0 0 16 16"  lc-helper="svg-icon"
                                             className="text-muted">
                                            <path
                                                d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.
                                                054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.
                                                279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.
                                                458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.
                                                612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.
                                                279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z">

                                            </path>
                                        </svg>
                                    </div>

                                    <div className="ms-3 align-self-center" >
                                        <p className="rfs-10">Quickly design and customize responsive mobile-first sites
                                            with Bootstrap, the world’s most popular front-end open source toolkit,
                                            featuring Sass variables and mixins, responsive grid system, extensive
                                            prebuilt components, and powerful JavaScript plugins.</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
                            <div className="lc-block mb-3">
                                <img className="rounded-start"
                                         src="https://images.unsplash.com/photo-1529068755536-a5ade0dcb4e8?crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8NDJ8fG1hbiUyMHB1cnBsZXxlbnwwfDJ8fHwxNjQ1Nzk5ODc2&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=1080&amp;h=1080"
                                         alt="Photo by Midas Hofstra" width="1080"
                                         srcSet="https://images.unsplash.com/photo-1529068755536-a5ade0dcb4e8?crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8NDJ8fG1hbiUyMHB1cnBsZXxlbnwwfDJ8fHwxNjQ1Nzk5ODc2&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=1080&amp;h=1080 1080w, https://images.unsplash.com/photo-1529068755536-a5ade0dcb4e8??crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8NDJ8fG1hbiUyMHB1cnBsZXxlbnwwfDJ8fHwxNjQ1Nzk5ODc2&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=150 150w, https://images.unsplash.com/photo-1529068755536-a5ade0dcb4e8??crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8NDJ8fG1hbiUyMHB1cnBsZXxlbnwwfDJ8fHwxNjQ1Nzk5ODc2&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=300 300w, https://images.unsplash.com/photo-1529068755536-a5ade0dcb4e8??crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8NDJ8fG1hbiUyMHB1cnBsZXxlbnwwfDJ8fHwxNjQ1Nzk5ODc2&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=768 768w, https://images.unsplash.com/photo-1529068755536-a5ade0dcb4e8??crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8NDJ8fG1hbiUyMHB1cnBsZXxlbnwwfDJ8fHwxNjQ1Nzk5ODc2&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=1024 1024w"
                                         sizes="(max-width: 1080px) 100vw, 1080px" />
                            </div>
                            <div className="lc-block">
                                <div className="d-flex px-3">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em"
                                             fill="currentColor" viewBox="0 0 16 16"  lc-helper="svg-icon">
                                            <path
                                                d="M7.202 15.967a7.987 7.987 0 0 1-3.552-1.26c-.898-.585-1.101-.826-1.101-1.306 0-.965 1.062-2.656 2.879-4.583C6.459 7.723 7.897 6.44 8.052 6.475c.302.068 2.718 2.423 3.622 3.531 1.43 1.753 2.088 3.189 1.754 3.829-.254.486-1.83 1.437-2.987 1.802-.954.301-2.207.429-3.239.33Zm-5.866-3.57C.589 11.253.212 10.127.03 8.497c-.06-.539-.038-.846.137-1.95.218-1.377 1.002-2.97 1.945-3.95.401-.417.437-.427.926-.263.595.2 1.23.638 2.213 1.528l.574.519-.313.385C4.056 6.553 2.52 9.086 1.94 10.653c-.315.852-.442 1.707-.306 2.063.091.24.007.15-.3-.319Zm13.101.195c.074-.36-.019-1.02-.238-1.687-.473-1.443-2.055-4.128-3.508-5.953l-.457-.575.494-.454c.646-.593 1.095-.948 1.58-1.25.381-.237.927-.448 1.161-.448.145 0 .654.528 1.065 1.104a8.372 8.372 0 0 1 1.343 3.102c.153.728.166 2.286.024 3.012a9.495 9.495 0 0 1-.6 1.893c-.179.393-.624 1.156-.82 1.404-.1.128-.1.127-.043-.148ZM7.335 1.952c-.67-.34-1.704-.705-2.276-.803a4.171 4.171 0 0 0-.759-.043c-.471.024-.45 0 .306-.358A7.778 7.778 0 0 1 6.47.128c.8-.169 2.306-.17 3.094-.005.85.18 1.853.552 2.418.9l.168.103-.385-.02c-.766-.038-1.88.27-3.078.853-.361.176-.676.316-.699.312a12.246 12.246 0 0 1-.654-.319Z"></path>
                                        </svg>
                                    </div>
                                    <div className="lc-block ps-4">
                                        <div >
                                            <h3 className="h5 ">Ralph Macchio<br/></h3>
                                            <p>Head of Marketing</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="py-6">
                <div className="container-fluid py-5 mb-4 bg-light rounded-3 "
                     style={{ background:'url(https://images.unsplash.com/photo-1579547621309-5e57ab324182?crop=entropy&amp;cs=tinysrgb&amp;fit=crop&amp;fm=jpg&amp;ixid=MnwzNzg0fDB8MXxzZWFyY2h8MTd8fHB1cnBsZXxlbnwwfDB8fHwxNjQ1NzkzMTY0&amp;ixlib=rb-1.2.1&amp;q=80&amp;w=1080&amp;h=1080)  center / cover no-repeat' }}>
                    <div className="p-5 mb-4 lc-block text-center">
                        <div className="lc-block mb-4">
                            <div >
                                <h2 className="h4 fw-light text-light">Ready to get in touch?</h2>
                            </div>
                        </div>
                        <div className="lc-block mb-5">
                            <div >
                                <h2 className="display-5 fw-bold text-light">Take your company</h2>
                                <h2 className="display-5 fw-bold text-light">to the Next Level</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section
                style={{ background: 'radial-gradient(116.18% 118% at 50% 100%, rgba(99, 102, 241, 0.1) 0%, rgba(218, 70, 239, 0.05) 41.83%, rgba(241, 244, 253, 0.07) 82.52%)'}}>
                <div className="container pt-5">
                    <div className="row min-vh-25 align-items-center text-center">
                        <div className="col-xl-4 mb-5 mb-xl-0">
                            <div className="lc-block">
                                <div className="d-inline-flex">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em"
                                             fill="currentColor" viewBox="0 0 16 16" lc-helper="svg-icon">
                                            <path
                                                d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"></path>
                                        </svg>
                                    </div>

                                    <div className="ms-3 align-self-center" >
                                        <p className="lead">972-413-0785 , 469-247-6545</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 mb-5 mb-xl-0">
                            <div className="lc-block">
                                <div className="d-inline-flex">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="2em"
                                             height="2em" viewBox="0 0 24 24"  lc-helper="svg-icon"
                                             fill="currentColor">
                                            <path
                                                d="M13 17H17V14L22 18.5L17 23V20H13V17M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H11V18H4V8L12 13L20 8V14H22V6A2 2 0 0 0 20 4M12 11L4 6H20Z"></path>
                                        </svg>
                                    </div>

                                    <div className="ms-3 align-self-center" >
                                        <p className="lead">info@basaccountingsolutions.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 mb-5 mb-xl-0">
                            <div className="lc-block">
                                <div className="d-inline-flex">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="2em"
                                             height="2em" viewBox="0 0 24 24"  lc-helper="svg-icon"
                                             fill="currentColor">
                                            <path
                                                d="M13 17H17V14L22 18.5L17 23V20H13V17M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H11V18H4V8L12 13L20 8V14H22V6A2 2 0 0 0 20 4M12 11L4 6H20Z"></path>
                                        </svg>
                                    </div>

                                    <div className="ms-3 align-self-center" >
                                        <p className="lead">info@arosuitesoft.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pb-5">
                    <div className="row text-center">
                        <div className="col-6 col-lg-3 py-5">
                            <div className="lc-block mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="3em" height="3em"
                                     lc-helper="svg-icon" fill="currentColor">
                                    <path
                                        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                </svg>
                            </div>
                            <div className="lc-block mb-4">
                                <div className="about-social">
                                    <p><a href="#" className="h3 text-dark text-decoration-none">Facebook</a></p>
                                </div>
                            </div>
                            <div className="lc-block col-2 offset-5 border-bottom border-2 border-dark">
                                <div >
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 py-5">
                            <div className="lc-block mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="3em" height="3em"
                                     lc-helper="svg-icon" fill="currentColor">
                                    <path
                                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                                </svg>
                            </div>
                            <div className="lc-block mb-4">
                                <div className="about-social">
                                    <p><a href="#" className="h3 text-dark text-decoration-none">Linkedin</a></p>
                                </div>
                            </div>
                            <div className="lc-block col-2 offset-5 border-bottom border-2 border-dark">
                                <div >
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 py-5">
                            <div className="lc-block mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="3em" height="3em"
                                     lc-helper="svg-icon" fill="currentColor">
                                    <path
                                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                </svg>
                            </div>
                            <div className="lc-block mb-4">
                                <div className="about-social">
                                    <p><a href="#" className="h3 text-dark text-decoration-none">Twitter</a></p>
                                </div>
                            </div>
                            <div className="lc-block col-2 offset-5 border-bottom border-2 border-dark">
                                <div >
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 py-5">
                            <div className="lc-block mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="3em" height="3em"
                                     lc-helper="svg-icon" fill="currentColor">
                                    <path
                                        d="M256 8C119.252 8 8 119.252 8 256s111.252 248 248 248 248-111.252 248-248S392.748 8 256 8zm163.97 114.366c29.503 36.046 47.369 81.957 47.835 131.955-6.984-1.477-77.018-15.682-147.502-6.818-5.752-14.041-11.181-26.393-18.617-41.614 78.321-31.977 113.818-77.482 118.284-83.523zM396.421 97.87c-3.81 5.427-35.697 48.286-111.021 76.519-34.712-63.776-73.185-116.168-79.04-124.008 67.176-16.193 137.966 1.27 190.061 47.489zm-230.48-33.25c5.585 7.659 43.438 60.116 78.537 122.509-99.087 26.313-186.36 25.934-195.834 25.809C62.38 147.205 106.678 92.573 165.941 64.62zM44.17 256.323c0-2.166.043-4.322.108-6.473 9.268.19 111.92 1.513 217.706-30.146 6.064 11.868 11.857 23.915 17.174 35.949-76.599 21.575-146.194 83.527-180.531 142.306C64.794 360.405 44.17 310.73 44.17 256.323zm81.807 167.113c22.127-45.233 82.178-103.622 167.579-132.756 29.74 77.283 42.039 142.053 45.189 160.638-68.112 29.013-150.015 21.053-212.768-27.882zm248.38 8.489c-2.171-12.886-13.446-74.897-41.152-151.033 66.38-10.626 124.7 6.768 131.947 9.055-9.442 58.941-43.273 109.844-90.795 141.978z"></path>
                                </svg>
                            </div>

                            <div className="lc-block mb-4">
                                <div className="about-social">
                                    <p><a href="#" className="h3 text-dark text-decoration-none">Dribble</a></p>
                                </div>
                            </div>
                            <div className="lc-block col-2 offset-5 border-bottom border-2 border-dark">
                                <div >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default About;
