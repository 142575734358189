import React from 'react';
import { useQuery } from "react-query";
import info from '../../../src/utils/data'



export  interface NewsItemProps {
    newsItemData: {
        newsTitle:string;
        newsSubTitle:string;

        ResourcesInsightsNewsFeed: {
            id: number;
            newsFeedIcon:string;
            newsFeedItemHeading:string;
            newsFeedItemDescriptionOne:string;
            newsFeedItemDescriptionTwo:string;
            newsFeedBg:string;
            newsFeedText:string;
        }[];
    };
}

const ResourcesInsightsNewsFeed = (props: NewsItemProps) => {
   const{ResourcesInsightsNewsFeed}=props.newsItemData;
    return (
        <section>
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="lc-block text-center col-md-8">
                        <h2  className=" mb-0"><b>{props.newsItemData.newsTitle}</b></h2>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="lc-block text-center col-xxl-6 col-md-8">
                        <div> <p  className=""> {props.newsItemData.newsSubTitle}</p></div>
                    </div>
                </div>
                <div className="row justify-content-center mt-3 mt-md-5">

                    {ResourcesInsightsNewsFeed.map(item=>(
                        <div className="col-md-6 col-lg-5 col-xl-3 mb-4 mb-xl-0">
                            <div className={`lc-block p-4 border rounded-3 ${item.newsFeedBg} ${item.newsFeedText}`}>
                                <div className="lc-block mb-3">
                                    <img src={item.newsFeedIcon} alt="" style={{width:"45px"}} />
                                </div>

                                <div className="lc-block mb-4">
                                    <div >
                                        <h4>{item.newsFeedItemHeading}</h4>
                                    </div>
                                </div>
                                <div className="lc-block mb-lg-5 mb-2">
                                    <div >
                                        <p>{item.newsFeedItemDescriptionOne}</p>
                                        <p>{item.newsFeedItemDescriptionTwo}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>

        </section>
    );
};

export default ResourcesInsightsNewsFeed;
