import Carousel from 'react-bootstrap/Carousel'
import { useQuery } from "react-query";
import '../../styles/carousel.css'
import {Animated} from "react-animated-css";



export  interface SliderItemProps {
    sliderItemData: {
        slider: {
            id: number;
            sliderImage: string;
            sliderHeading:string;
            sliderDescription: string;
        }[];
    };
}

const Slider = (props: SliderItemProps) => {
   const {slider} =props.sliderItemData;
    return (
        <Carousel fade>
            {slider.map(item=>(
                <Carousel.Item className="drk">
                    <img
                        className="d-block w-100"
                        src={item.sliderImage}
                        alt=""
                    />
                    <Carousel.Caption>
                        <Animated animationIn="bounceInDown" animationOut="fadeOut" isVisible={true}>
                            <h1>{item.sliderHeading}</h1>
                        </Animated>
                        <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true}>
                            <p>{item.sliderDescription}</p>
                        </Animated>

                    </Carousel.Caption>
                </Carousel.Item >
            ))}
        </Carousel>
    );
};

export default Slider;
