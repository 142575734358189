import React from 'react';
import { useQuery } from "react-query";
import info from "../../utils/data";



export  interface WhyItemProps {
    whyItemData: {
        whyArea: {
            id: number;
            whyImage:string;
            whyUseItemHeading:string;
            whyUseItemDescription:string;
            imgOrder:string;
            textOrder:string;
        }[];
    };
}

const WhyUse = (props: WhyItemProps) => {
   const{whyArea}=props.whyItemData;
    return (
        <div>
            <section>
                <div className="container-fluid p-0 overflow-hidden ">
                    {whyArea.map(item=>(
                        <div className="row g-0 bg-two">
                            <div  className={`col-lg-6 ${item.imgOrder}`}
                                  style={{
                                      backgroundImage:`url(${item.whyImage})`,
                                      minHeight: '60vh',
                                      backgroundSize: 'cover',
                                      backgroundRepeat: 'no-repeat',
                                      backgroundPosition:'center center'
                                  }}>
                            </div>
                            <div className={`col-lg-6 ${item.textOrder}  my-auto  px-5 py-5  text-light`}>
                                <div className="lc-block">
                                    <div >
                                        <h1>{item.whyUseItemHeading}</h1>
                                        <p className="lead">{item.whyUseItemDescription}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>





            {/*<section className="py-3">*/}
            {/*    <div className="container-fluid p-0 overflow-hidden">*/}

            {/*        {whyArea.map(item=>(*/}
            {/*            <div className="row g-0">*/}
            {/*                <div  className={`col-lg-6 ${item.imgOrder}`}*/}

            {/*                      style={{ minHeight: "45vh", backgroundSize: "cover", backgroundPosition: "center",*/}
            {/*                          backgroundImage:`url(${item.whyImage})`}}>*/}

            {/*                </div>*/}
            {/*                <div className={`col-lg-6 ${item.textOrder} bg-two  text-light my-auto  px-5 py-5 `}>*/}
            {/*                    <div className="lc-block">*/}
            {/*                        <div >*/}
            {/*                            <h1>{item.whyUseItemHeading}</h1>*/}
            {/*                            <p className="lead">{item.whyUseItemDescription}</p>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        ))}*/}
            {/*    </div>*/}

            {/*</section>*/}


        </div>
    );
};

export default WhyUse;
